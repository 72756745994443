.dashboardHeading {
  color: #00ffff;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 1.1rem;
}
.godjiraContainer {
  display: flex;
  /* width: 65rem; */
  /* justify-content: space-between; */
  background: linear-gradient(
    90deg,
    rgba(28, 51, 103, 0.5) 0%,
    rgba(8, 17, 36, 0) 98.28%
  );
  border-radius: 10px;
  padding: 5px 0.5rem;
}
.width-70 {
  width: 57rem;
}
.dasboardContainer {
  height: 40rem;
  width: 59rem;
  margin-left: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.filter {
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  color: white;
}
.filterObject {
  height: 11.25rem;
  width: 11.25rem;
}
.filterObject:hover {
  border:3px solid #00ffff !important;
  border-radius: 15px !important;
}
.emptyObject {
  height: 11.25rem;
  width: 11.25rem;
}
.card {
  background-color: #161b59 !important;
}
.card-img {
  border-radius: 13px;
}
/* .dashBoard {
  width: max-content;
  display: flex;
  justify-content: center;
} */
.filterBackground {
  border-radius: 13px;
  /* height: auto; */
  /* height: 11rem; */
  width: 100%;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}
.emptyBackground {
  padding: 3px 0 0 0;
  border-radius: 10px;
  height: 11.25rem;
  width: 11.25rem;
}
.card-body {
  position: relative;
}
.mar-neg {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgb(0 0 0 / 70%);
    border-radius: 0 0 13px 13px;
}
.mar-neg h5{
  font-weight: bolder;
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;
}
.mar-neg p{
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0;
}
.staking_button {
  font-family: "Ubuntu";
  font-weight: 700;
  white-space: nowrap;
  border: 3px solid #00ffff;
  color: #fff;

}
button {
  outline: none;
  background-color: transparent;
  border-radius: 5px !important;
  padding: 7px 15px;
}
.staking_button:hover {
  transition: 0.3s;
  background-color: #00ffff;
  border: 3px solid #182b59;
  color: #182b59;
}


.dashboardModal {
  z-index: 999;
  position: absolute;
  background: #0f1d3d;
  border-radius: 15px;
  padding: 20px;
  margin: 2rem 0 0 1rem;
}
.filter-item {
  color: white;
  font-weight: bold;

  line-height: 1rem;
}
.filter-item p{
  text-transform: capitalize;
}
input {
  height: 0;
  width: 0;
  position: absolute;
  margin: 0 0 0 1rem;
}

.checkmark {
  height: 16px;
  width: 16px;
  margin: 0 0 0 1rem;
  background: #1c3367;
  border: 1.5px solid #ffffff;
  border-radius: 2px;
}

.filterObjects {
  height: 40rem;
  max-height: 50rem;
  overflow: auto;
  overflow-x: hidden;
}
.filter-item input:checked ~ .checkmark {
  border: 1.5px solid #00ffff;
}
.dashboardMain {
  display: flex;
  overflow: auto;
  justify-content: center;
  /* width: 70%; */
  justify-content: center;
  margin: 1.7rem 2.3rem;
}
.dashboardHeadings {
  display: flex;
  justify-content: space-between;
  /* margin: 0 2rem; */
}
.filterMar {
  margin-right: 1rem;
}
.filterContainer {
  width: 14.4rem;
  /* margin-left: 30rem; */
}
.marlef {
  margin-left: 1.3rem;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(78, 91, 121, 0.25);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e5b79;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: url(../assets/bg.jpeg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow-x: hidden;
}
.color-white-text{
  color: #fff;
  text-align: center;
  padding: 13px 20px 0 20px;
  font-size: 13px;
}
.brand-logo img {
  display: block;
  margin: 30px auto 0 auto;
  text-align: center;
}
.button-div {
  width: 580px;
  /* height: 250px; */
  height:auto;
  background-color: rgba(0, 0, 0, 0.1);
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 15px;
  padding: 40px 0;
  box-shadow: -2px 1px 13px 36px rgba(0, 0, 0, 0.07) inset;
  -webkit-box-shadow: -2px 1px 13px 36px rgba(0, 0, 0, 0.07) inset;
  -moz-box-shadow: -2px 1px 13px 36px rgba(0, 0, 0, 0.07) inset;

}
/* .button-div {
  width: 580px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 15px;
  box-shadow: -2px 1px 13px 36px rgba(0, 0, 0, 0.07) inset;
  -webkit-box-shadow: -2px 1px 13px 36px rgba(0, 0, 0, 0.07) inset;
  -moz-box-shadow: -2px 1px 13px 36px rgba(0, 0, 0, 0.07) inset;
} */
.connect {
  border: none;
  outline: none;
  position: absolute;
  top: 45%;
  left: 50%;
  font-weight: bold;
  white-space: nowrap;
  font-size: 1.2rem;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, 80%);
  border-radius: 5px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 30px;
  color: #00ffff;
  overflow: hidden;
  transition: 0.2s;
  border: 1px dotted #00ffff;
}
.connect:hover {
  border-radius: 5px;
  color: #fff;
  background: #00ffff;
  box-shadow: 0 0 10px #00ffff, 0 0 40px #00ffff, 0 0 80px #00ffff;
  transition-delay: 1s;
  cursor: pointer;
}
.connect span {
  position: absolute;
  display: block;
}
.connect span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #00ffff);
}
.connect:hover span:nth-child(1) {
  left: 100%;
  transition: 1s;
}
.connect span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #00ffff);
}
.connect:hover span:nth-child(3) {
  right: 100%;
  transition: 1s;
  transition-delay: 0.5s;
}
.connect span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #00ffff);
}
.connect:hover span:nth-child(2) {
  top: 100%;
  transition: 1s;
  transition-delay: 0.25s;
}
.connect span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #00ffff);
}
.connect:hover span:nth-child(4) {
  bottom: 100%;
  transition: 1s;
  transition-delay: 0.75s;
}
.tab-content {
  background: rgba(0, 0, 0, 0.07);
  padding: 2rem;
}
.cursor-pointer {
  cursor: pointer;
}
button {
  border: none;
  outline: none;
}
.button_stakingss{
  bottom: -75vh;
  right: 4rem;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  color: #fd02fd;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Menlo";
  text-transform: uppercase;
}
.button_stakingss:hover{
  transition: .2s;
  color: #00ffff;
}
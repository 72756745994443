.navButton {
  display: flex;
  padding: 0 1.4rem;
  align-items: center;
  color: #00ffff;
  font-family: "Roboto", sans-serif;
  height: 3.5rem;
  border-radius: 5px;
  border: 3px solid #00ffff;
}

.wallet-container {
  margin: 1rem 2rem 0 0;
  display: flex;
  font-size: large;
  font-weight: bold;
  align-items: flex-end;
  color: white;
  font-family: "Ubuntu", sans-serif;
}
.wallet {
  width: 1.9rem;
  height: 2rem;
  margin-right: 0.3rem;
}
.detail {
  margin-top: -2rem;
  display: flex;
}
.connection {
  margin-top: 1rem;
}
.dashboard {
  font-family: "Osake";
  color: #fff;
  font-size: 3rem;
}
.boxxx {
  margin-right: 1rem;
}
.bold {
  font-weight: 600;
  margin: 0 5px 0 0;
}
.modalDetails {
  background-color: #00ffff;
  position: absolute;
  font-family: "Roboto", sans-serif;
  color: white;
  background: #0f1d3d;
  padding: 20px 40px 10px 20px;
  line-height: 15px;
  border-radius: 15px;
  left: 30%;
  top: 1%;
}
